<template>
  <!-- sub category page -->
  <div class="subCategory" :class="$i18n.locale">
    <div
      class="navigation d-none d-lg-flex justify-content-start align-items-center"
    >
      <!-- navigation -->
      <nav aria-label="breadcrumb ">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">
              {{ $t("items.home") }}
            </router-link>
          </li>
          <li class="breadcrumb-item" v-if="categoryTitle">
            <router-link :to="`/categories/${categoryId}`">
              {{ categoryTitle }}
            </router-link>
          </li>
          <li class="breadcrumb-item" v-else>
            <span>{{ $t("home.noDataTill") }}</span>
          </li>
        </ol>
      </nav>
    </div>

    <!-- Cover Image -->
    <SingleImageCover :image="cover_image_path" v-if="cover_image_path" />

    <!-- <div class="data-holder">
      <div class="container">
        <div class="row">
          <div class="col-md-9 col-sm-12 mb-3">
            <div class="container" id="people">
              <div class="row">
                <div class="col-sm-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <section>
      <div class="container">
        <div class="tabs-holder">
          <div class="tabs-content VueSlickCarousel-holder">
            <!-- Replace tabs with slider -->
            <div class="row align-items-center">
              <div class="col-md-1 col-sm-12 mb-2">
                <div
                  @click="selectDefaultTab"
                  class="category-slide mb-5 allCat cursor-pointer"
                  :class="{ active: currentActiveCategory === 'All' }"
                >
                  <img
                    src="@/assets/images/book-open-reader-solid.svg"
                    alt="table-image"
                    width="75"
                    height="75"
                  />
                  <p class="small-text mb-0 mt-2 text-center">
                    {{ $t("home.All") }}
                  </p>
                </div>
              </div>
              <div class="col-md-11 col-sm-12 mb-2">
                <carousel
                  :perPage="slidesPerPage"
                  :navigationEnabled="true"
                  :paginationEnabled="false"
                >
                  <!-- Categories Slider -->
                  <Slide
                    v-for="(category, index) in subCategories"
                    :key="index"
                    @click.native="selectTab(category)"
                    :class="[
                      'category-slide d-flex justify-content-center align-items-center flex-column cursor-pointer',
                      { active: currentActiveCategory === category.title },
                    ]"
                  >
                    <img
                      :src="category.icon_image_path"
                      alt="category_image"
                      class="categoryImage"
                    />
                    <p
                      class="small-text"
                      v-if="category.title.length > 12 && mobile"
                    >
                      {{ category.title.slice(0, 11) + "..." }}
                    </p>
                    <p class="small-text text-center" v-else>
                      {{ category.title }}
                    </p>
                  </Slide>
                </carousel>
              </div>
            </div>

            <!-- Content for selected tab -->
            <div v-if="myActiveAtt === 'All'">
              <!-- Display all categories -->
              <b-row v-if="loading">
                <b-col class="mb-2" lg="3" sm="6" v-for="x in 10" :key="x">
                  <b-skeleton-img></b-skeleton-img>
                  <b-card>
                    <b-skeleton
                      animation="fade"
                      width="60%"
                      class="border-none"
                    ></b-skeleton>
                    <b-skeleton
                      animation="fade"
                      width="85%"
                      class="border-none"
                    ></b-skeleton>
                  </b-card>
                </b-col>
              </b-row>
              <b-row
                v-else-if="!loading && allSubCategoriesLength > 0"
                align-h="center"
                align-v="center"
              >
                <b-col
                  lg="3"
                  sm="6"
                  class="custum-padding mb-3 p-0"
                  :title="category.title"
                  v-for="category in finalAllSubTogether"
                  :key="category.id"
                >
                  <div class="mb-4">
                    <router-link
                      :to="`/categories/${category.id}/variants`"
                      v-if="category.id"
                    >
                      <OtherCategoryCard
                        v-if="category"
                        :card="{ type: category.title }"
                        :image="category.image_path"
                      />
                    </router-link>
                  </div>
                </b-col>
              </b-row>
              <div class="" v-else-if="!loading && allChildrenLength <= 0">
                <h3 class="my-2">{{ $t("home.noDataTill") }}</h3>
              </div>
            </div>

            <!-- Display category specific items if a category is selected -->
            <div v-else>
              <b-row align-h="center" align-v="center" v-if="!loading">
                <b-col
                  lg="3"
                  sm="6"
                  class="custum-padding mb-3 p-0"
                  v-for="cat in activeCategoryChildren"
                  :key="cat.id"
                >
                  <router-link :to="`/categories/${cat.id}/variants`">
                    <OtherCategoryCard
                      :card="{ type: cat.title }"
                      :image="cat.image_path"
                      class="homecategories"
                    />
                  </router-link>
                </b-col>
              </b-row>
              <div class="" v-else>
                <h3 class="my-2">
                  {{ $t("home.noData") }} {{ activeCategory.title }}
                  {{ $t("home.tilNow") }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import OtherCategoryCard from "@/components/global/OtherCategoryCard.vue";
import categories from "@/services/categories";
import SingleImageCover from "@/components/pages/home/SingleImageCover.vue";
// import VueSlickCarousel from "vue-slick-carousel";
// import "vue-slick-carousel/dist/vue-slick-carousel.css";
// import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { Carousel, Slide } from "vue-carousel";
export default {
  data() {
    return {
      id: this.$route.params.slug,
      myActiveAtt: "All", // Track the currently active tab
      currentActiveCategory: "All", // Track the currently active slide
      loading: true,
      subCategories: [],
      allChildrenLength: null,
      finalAllSubTogether: null,
      allSubCategoriesLength: null,
      cover_image_path: null,
      categoryTitle: null,
      categoryId: null,
      activeCategoryChildren: [], // Children of selected category
      activeCategory: null,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 12,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 1500,
            settings: {
              slidesToShow: 7,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1191,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 820,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      slidesPerPage: 5,
    };
  },
  components: {
    OtherCategoryCard,
    SingleImageCover,
    // VueSlickCarousel,
    Carousel,
    Slide,
  },
  methods: {
    adjustSlidesPerPage() {
      const width = window.innerWidth;
      if (width >= 1200) {
        this.slidesPerPage = 5; // Large screens
      } else if (width >= 992) {
        this.slidesPerPage = 4; // Medium screens
      } else if (width >= 768) {
        this.slidesPerPage = 3; // Tablet screens
      } else if (width >= 576) {
        this.slidesPerPage = 2; // Small screens
      } else {
        this.slidesPerPage = 1; // Extra small screens (phones)
      }
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1199) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },

    async getSubCategories() {
      let data = {
        parent_id: this.id,
        keyword: this.searchWord,
      };
      await categories
        .getSubCategories(data)
        .then((resp) => {
          this.subCategories = resp.data.items;
          this.allChildrenLength = resp.data.items.length;

          let allSubTogether = [];
          let finalAllSubTogether = [];
          for (let index = 0; index < resp.data.items.length; index++) {
            const element = resp.data.items[index].all_children;
            allSubTogether.push(element);
          }

          allSubTogether.forEach((element) => {
            for (let index = 0; index < element.length; index++) {
              const element2 = element[index];
              finalAllSubTogether.push(element2);
            }
          });
          this.finalAllSubTogether = finalAllSubTogether;

          this.categoryTitle = resp.data.items[0].parent_category.title;
          if (this.categoryTitle) {
            document.title = this.categoryTitle;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async getAllSubCategories() {
      await categories
        .getAllSubCategories(this.id)
        .then((resp) => {
          this.allSubCategories = resp.data.items;
          this.allSubCategoriesLength = resp.data.items.length;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getCover() {
      categories.getSingleProductDetails(this.id).then((res) => {
        if (!this.categoryTitle) {
          this.categoryTitle = res.data.items.title;
        }
        this.categoryId = res.data.items.id;
        this.cover_image_path = res.data.items.cover_image_path;
        sessionStorage.setItem("parentTitle", res.data.items.title);
      });
    },

    // Select the "All" tab and clear any query
    selectDefaultTab() {
      this.myActiveAtt = "All";
      this.currentActiveCategory = "All"; // Set "All" as active
      this.activeCategoryChildren = [];
      this.$router.push({ path: this.$route.path, query: {} });
      var tabsHeight =
        document.querySelector(".VueSlickCarousel-holder").offsetTop - 220;
      // Optionally scroll to the top or any position
      setTimeout(() => {
        window.scrollTo({
          top: tabsHeight,
          left: 0,
          behavior: "smooth",
        });
      }, 300);
      this.getSubCategories(); // Reload the subcategories
    },

    // Select a specific category
    selectTab(item) {
      this.myActiveAtt = item.title.trim().replace(/\s/g, "");
      this.currentActiveCategory = item.title; // Set the active slide
      this.activeCategoryChildren = item.all_children || [];
      let query = { brand: item.title.trim().replace(/\s/g, "") };
      this.$router.push({ path: this.$route.path, query });

      var tabsHeight =
        document.querySelector(".VueSlickCarousel-holder").offsetTop - 220;
      // Optionally scroll to the top or any position
      setTimeout(() => {
        window.scrollTo({
          top: tabsHeight,
          left: 0,
          behavior: "smooth",
        });
      }, 300);
    },
  },
  created() {
    this.getAllSubCategories();
    this.getSubCategories();
    this.getCover();
    sessionStorage.setItem("catId", this.id);
  },
  mounted() {
    this.adjustSlidesPerPage(); // Call on component mount
    window.addEventListener("resize", this.adjustSlidesPerPage);
    if (this.$route.query.brand) {
      // If there's a query, clear it and reload the default data
      this.$router.push({ path: this.$route.path, query: {} });
      this.myActiveAtt = "All";
      this.currentActiveCategory = "All";
      this.getSubCategories();
    } else {
      this.myActiveAtt = "All";
      this.currentActiveCategory = "All"; // Default to "All"
    }
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  metaInfo: {
    title: document.title ? document.title : "",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: `${localStorage.getItem("lang")}`,
      amp: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.cover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.cover-data {
  background: rgba(0, 0, 0, 0.35);
}

.select {
  border: none;
  outline: none;
  box-shadow: none;
}

form {
  .xt-blog-form {
    margin-top: 50px;
  }

  .input-group {
    display: block !important;
  }

  .input-group-btn {
    position: absolute;
    right: 5%;
    top: -12%;
    bottom: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

input {
  &:hover,
  &:focus {
    box-shadow: none;
  }
}

div:empty {
  display: none !important;
  opacity: 0;
}

.custum-padding {
  .category-card {
    margin-right: 7px;
  }
}

.navigation {
  background-color: #f3f4f6;
}

.categoryImage {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px !important;
  background: #000;
  padding: 2px;
}

.small-text {
  font-size: 16px;
}

.breadcrumb-item a {
  font-weight: normal !important;
}

.active {
  border-radius: 50%;
  img {
    padding: 2px;
    border: 3px solid $main-color; /* Example active styling */
    box-shadow: 0px 0px 2px 2px $main-color;
  }
}
.active.allCat {
  img {
    border-radius: 5px !important;
  }
}
</style>
